const en = {
  navi: {
    product: 'Products',
    about: 'About Us',
    contact: 'Contact Us',
  },
  lang: {
    en: 'English',
    tw: 'Taiwan'
  },
  home: {
    vision: 'Vision',
    inspection: 'Inspection',
    system: 'System',
    strong: 'Strong',
    strongTxt: '強大彈性的檢測功能',
    completed: 'Completed',
    completedTxt: '完整且詳細的統計資料',
    efficient: 'Efficient ',
    efficientTxt: '簡易有效的操作軟體',
    productive: 'Productive',
    productiveTxt: '高質量帶來產品競爭力',
    lookingFor1: '您在尋找的',
    lookingFor2: '正是我們可以實現的',
    lookingFor3: '.....100%質量檢測',
    ourProducts: '我們的產品',
    warper: '整經檢測',
    warperTxt: '可檢測整經機上的原紗有無破絲、毛球，監控原紗品質',
    ccl: '銅箔檢測',
    cclTxt: '導入業界獨有的分光系統，先進的多角度技術，使單組iGuard相機可同時檢測銅箔正反面',
    glass: '布污檢測',
    glassTxt: '使用iGuard自行研發的環型光源，可消除玻纖布織紋進而檢測到顏色更淺更小的布汙',
    prepreg: '黏合片檢測',
    prepregTxt: '導入業界獨有的分光系統，可同時檢測正面光與背面光，從而實現同時檢測黑點與針孔兩種需要不同光源的缺陷',
    customized: '客製化服務',
    customizedTxt: '依据貴公司定義品質標準，開發正確的檢測技術並精確的提供缺陷檢測報告',
    seeMore: '查看更多',
    news: '新聞',
    newsTitle1: '2024 TPCA（台灣電路板產業國際展覽會）',
    newsTxt1: '由中華民國對外貿易發展協會(TAITRA)及台灣區電機電子工業同業公會(TEEMA)共同主辦，2024年「台灣電路板產業國際展覽會（TPCA）」及「台灣國際人工智慧暨物聯網展(AIoT Taiwan)」於今(18)日完美落幕。兩展共有來自臺灣、波蘭、日、韓、星、泰、香港及中國大陸等310家廠商參展，3天展期共吸引全球68個國家、逾1萬3,000名買主及業者進行參觀採購。',
    newsTitle2: '未來：2025 TPCA（台灣電路板產業國際展覽會）',
  },
  about: {
    companyHistory: 'Company\'s history',
    descriptionTitle1: 'Masterwork Aoitek Tech Corp Ltd.',
    descriptionTxt1: 'iGuard aoitek\'s headquartered in Xizhi Far Eastern U-Town, and has developed high-end inspection technology cooperation with many world-class companies for a long time. Its visual inspection brand iGuard provides machine vision inspection solutions in various fields, including printing inspection, electronic material inspection, textile inspection, glass inspection, etc. Provide complete automated testing solutions for the quality control of products produced by major companies.The current operating project is in a niche market. We create products developed using the platform, so that RD can focus on maintaining the platform. FAE uses the platform and develops various products on the platform to provide business sales. Improving the consistency of the products, and thus increasing the company\'s competitiveness in the global market, will eventually have a place in the global market.',
    descriptionTitle2: 'Serving more than 25 countries',
    descriptionTxt3: '我們服務的客戶遍佈全世界，包含中國大陸、德國、日本、美國、東南亞等共25個國家以上。有世界第一大印刷設備龍頭──海德堡印刷；電子材料業標竿企業如南亞塑膠、台光電子、生益電子等。',
    descriptionTxt4: '我們提供的機器視覺檢測方案含括自動化「膠印機在線檢測」、「印刷光膜材料檢測」、「銅箔基板檢測」、「含浸材料檢測」、「電子布檢測」、「電子布原料檢測」、「紡織布料檢測」、「汽車玻璃檢測」等。並獲得國內外許多標竿企業客戶的高度肯定。',
    descriptionTxt5: '我們擁有經驗豐富及優秀的研發團隊，以優異的產品及創新能力，可以為連續高速工業生產線上提供一整套品質管控的解決方案，讓您同時能提高良率、降低生產成本，並獲得更高的產品品質以及生產效率。',
    countries25: '25countries',
    cooperation: 'Cooperation',
    location: 'Location',
    taiwan: 'Taiwan',
    china: 'China',
    taiwan_en: 'Taiwan Region',
    china_en: 'China Region',
    address1: '地址：22175新北市汐止區新台五路一段95號29樓之9（遠雄 U-Town C棟）<br>電話 : +886 2 7708-7550<br>傳真 : +886 2 7708-7560<br>郵件：sales@aoitek.com',
    address2: '地址：天津市南開區北方城三區37-1-2923<br>電話  : +86 22 8368-0882<br>傳真  : +86 22 8368-0882<br>郵件：sales@aoitek.com',
    address1_en: 'Address: 29F-9, No. 95, Sec. 1, Xintai 5th Rd., Xizhi District, New Taipei City<br/>22175 (Far Eastern U-Town Building C)<br/>Phone: +886 2 7708-7550<br/>Fax: +886 2 7708-7560 <br/>Email: sales@aoitek.com<br/>',
    address2_en: 'Address: 37-1-2923, Northern City, Nankai District, Tianjin <br/>Phone: +86 22 8368-0882<br/>Email: sales@aoitek.com<br/>',
    recruitmentInfo: 'Recruitment Information',
    joinUs: 'Join us',
    joinUsTxt: '我們致力於提供舞台給每個有能力的人，團隊陣容來自四面八方各種領域的專家，有來自台清交各頂尖大專院校及宏都拉斯 印尼 等國際化人才，我們熱愛創新、接受新觀念, 不做me too產品。我們不喜歡用嘩眾取寵的廣告詞, 但期待有熱血. 做事踏實的各方好手加入我們的團隊，一起成長茁壯 。',
  },
  contact: {
    companyLocation: '企業分部',
    moreInfo: 'More Information',
    fullName: 'Full name',
    lastName: 'Last name',
    email: 'Email',
    company: 'Company',
    phone: 'Phone',
    country: 'Country',
    description: 'Description',
    send: 'Send',
  },
  product: {
    inspectionItem: 'Inspection item',
    warperInspection: 'Warper Inspection',
    warperTxt1: '可檢測整經機上的原紗有無破絲、毛球，監控原紗品質，進而得知每綑經軸的質量，若特定原紗持續檢測到瑕疵，也可即時更換原紗避免品質下降與傳統的遮斷或是張力檢測經斷不同，iGuard使用相機檢測，可正確計算經線數量，在發生經斷時可立即通知並輸出停機訊號與蜂鳴器',
    warperTxt2: '檢測項目：毛球，經線破絲，經斷',
    warperTxt3: '檢測方式：高解析度CMOS Line scan camera',
    warperTxt4: 'warper defeat image',
    warperTxt5: '破絲',
    glassFabricInspection: 'Glass Fabric Inspection',
    glassTxt1: '使用iGuard自行研發的環型光源，可消除玻纖布織紋進而檢測到顏色更淺更小的布汙，並同時檢測正反面，確保能檢測到任何微小的瑕疵，監控生產品質所有的瑕疵都能記錄米數與位置並生成報表供人工複捲檢驗，並通過換軸訊號自動切換並生成新的報表。',
    glassTxt2: '檢測項目：布污',
    glassTxt3: '檢測方式：高解析度CMOS Line scan camera',
    glassTxt4: 'weaving defeat image',
    glassTxt5: 'Tight end',
    glassTxt6: 'Hole',
    glassTxt7: 'wrong end',
    glassTxt8: 'broken',
    glassTxt9: 'double weft',
    glassTxt10: 'double warp',
    glassTxt11: 'Reed',
    glassTxt12: 'broken warp',
    glassTxt13: 'wrong pick',
    glassTxt14: 'broken weft',
    glassTxt15: 'Glass Fabric defeat image',
    glassTxt16: 'Dirt',
    prepregInspection: 'Prepreg Inspection',
    prepregTxt1: '導入業界獨有的分光系統，可同時檢測正面光與背面光，從而實現同時檢測黑點與針孔兩種需要不同光源的缺陷，節省機台空間。自製的環型光源可以消除厚薄布、PP抖動、織紋等影響，使黑點、汙類等缺陷檢測更穩定。高解析度的彩色相機可因應不同顏色的PP，並加強缺膠、膠粒等檢測效果。可準確輸出停機訊號，並配缺陷指示燈，清楚指出缺陷位置，便於線上操作工處理缺陷',
    prepregTxt2: '檢測項目：黑點、蚊蟲、污類、膠粒、針孔、缺膠',
    prepregTxt3: '檢測方式：高解析度CMOS Line scan camera',
    prepregTxt4: 'Prepreg defeat image',
    prepregTxt5: 'Spot',
    prepregTxt6: 'Bug',
    prepregTxt7: 'Creases',
    prepregTxt8: 'Foreign Matter',
    prepregTxt9: 'Pilling',
    prepregTxt10: '針孔',
    prepregTxt11: '缺膠',
    cclInspection: 'CCL(copper clad laminate) Inspection',
    cclTxt1: '導入業界獨有的分光系統，先進的多角度技術，使單組iGuard相機可同時檢測兩組不同角度的圖像即亮面銅全反射光(平面類缺陷)，側向光1(凹凸類缺陷)測量光2(凸起類缺陷)，側向光3(破損類缺陷)，反轉銅箔全反射光(平面類缺陷)對比傳統CCD技術，iGuard即能大幅減少多角度技術相機配置的數量，同時也可獲取多組角度的圖像，巧妙的將多個角度的圖像合併為一，為深度學習提供更多信息，大幅提高CCD檢測性能，CCD檢測輸送機台占地空間最小1500mm，即可實現雙面同時檢測，節省占地空間。',
    cclTxt2: '檢測項目：凹點、氧化、破銅、皺紋、氣泡',
    cclTxt3: '檢測方式：高解析度CMOS Line scan camera',
    cclTxt4: '銅箔凹凸瑕疵影像',
    cclTxt5: 'Bad Cutting',
    cclTxt6: 'Wrinkle',
    cclTxt7: 'Scratching',
    cclTxt8: 'Dent',
    cclTxt9: 'Pilling',
    cclTxt10: 'Pits',
    cclTxt11: '銅箔外觀瑕疵影像',
    cclTxt12: 'Printing Shift',
    cclTxt13: 'Oxidation',
    cclTxt14: 'Oxidation PND',
    cclTxt15: 'Spot',
    customizeService: 'Customize Service',
    customizeTxt1: 'Our experienced team can customize the development and meet the various needs of cooperating manufacturers, define the quality standards according to your company, develop the correct inspection technology and accurately provide defect inspection reports.iGuard Inspection Technology sincerely invites you to contact our team. We will provide a complete quality control testing method and a cost-effective solution according to your company\'s needs.',
  },
  error: {
    emailInvalid: 'The email field must be a valid email.',
    fieldRequired: 'The {field} field is required.',
    maxchar: '{num}-character limit.',
    fillNumber: 'Please fill the Number.',
    fillFormCorrect: 'Please fill the form correctly.'
  },
  pageSuccess: {
    sendmailsuccess: "Email sent successfully.",
  }
}

export default en;
