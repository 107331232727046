import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// i18n lang plugin & files
import { locales } from './locales/index.js';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';

Vue.use(VueI18n);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

/* eslint-disable no-new */
// Create VueI18n instance with options
const i18n = new VueI18n({
  // locale: browserlang, // set locale
  locale: 'tw', // no other lang use en default
  messages: locales, // set locale messages
  fallbackLocale: 'en'
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
