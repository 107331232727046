import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/Home.vue')
  }, {
    path: '/product',
    name: 'product',
    component: () => import('@/pages/Product.vue')
  }, {
    path: '/about',
    name: 'about',
    component: () => import('@/pages/About.vue')
  }, {
    path: '/contact',
    name: 'contact',
    component: () => import('@/pages/Contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history', // remove hashtag url
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if ( !(to.path.match('help/category') && (/iPhone|Android/i.test(navigator.userAgent))) ) {
        return { x: 0, y: 0 }
      }
    }
  }
})

export default router
