import en from './en'
// import fr from './fr'
// import it from './it'
// import de from './de'
// import es from './es'
import tw from './tw'
// import jp from './jp'

export const locales = {
  en,
  // fr,
  // it,
  // de,
  // es,
  tw,
  // jp
}
