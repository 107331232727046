<template>
  <div id="app">
    <nav class="navbar is-fixed-top " role="navigation" aria-label="main navigation">
      <div class="container">
        <div class="navbar-brand">
          <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasic" @click="isOpen = !isOpen" :class="{'is-active': isOpen}">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
          <router-link :to="'/'" class="navbar-item navbar-logo">
            <img src="@/images/logo_iguard.svg">
          </router-link>
        </div>
        <div id="navbarBasic" class="navbar-menu" :class="{'is-active': isOpen}">
          <div class="navbar-end">
            <div class="navbar-item" @click="isOpen = !isOpen">
              <router-link :to="'/product'" :class="{'is-active': $route.name === 'product'}">{{ $t('navi.product') }}</router-link>
            </div>
            <div class="navbar-item" @click="isOpen = !isOpen">
              <router-link :to="'/about'" :class="{'is-active': $route.name === 'about'}">{{ $t('navi.about') }}</router-link>
            </div>
            <div class="navbar-item" @click="isOpen = !isOpen">
              <router-link :to="'/contact'" :class="{'is-active': $route.name === 'contact'}">{{ $t('navi.contact') }}</router-link>
            </div>
            <div class="navbar-item" @click="isOpen = !isOpen">
              <router-link :to="'/contact'" class="phone">+886 2 2788-2112</router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="main-content">
      <transition name="fade">
        <router-view/>
      </transition>
    </div>
    <footer>
      <PageFooter />
      <!-- <p style="color: red; font-weight: bold;">Sorry ths Internet Explorer is out of support, please use Microsoft Edge, Mozilla Firefox, or Google Chorme.</p> -->
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    PageFooter: () => import('@/components/PageFooter.vue'),
  },
  data: () => ({
    isOpen: false
  }),
  mounted () {
    this.$nextTick(() => {
      if (!/iPhone|Android/i.test(navigator.userAgent)) {
        this.detectScroll()
        window.addEventListener('scroll', () => {
          this.detectScroll()
        }, false)
      }
    })
  },
  methods: {
    detectScroll () {
      if (window.pageYOffset > 200) {
        document.querySelector('.navbar').style.backgroundColor = 'rgba(48, 48, 48, 1)'
      } else if (window.pageYOffset <= 10) {
        document.querySelector('.navbar').removeAttribute('style')
      }
    },
    closeCookies () {
      this.isCookies = false;
      localStorage.LollipopWebCookies = 'close';
    },
  }
}
</script>

<style lang="sass">
  @import '../node_modules/bulma/bulma'
  @import 'css/app'
</style>
<style lang="sass" scoped>
.fade-enter-active, .fade-leave-active
  opacity: 1
  transition: opacity .3s

.fade-enter, .fade-leave-to
  opacity: 0
.navbar-item
  color: #ffffff
  a
    color: #ffffff
    border-bottom: 2px solid transparent
    padding: 0 8px
    &:hover
      border-radius: 3px
      background-color: #d9d9d9
  .is-active
    border-bottom: 2px solid #005cb9
  .phone
    padding: 12px 16px
    border-radius: 28.5px
    background-color: #005cb9
    &:hover
      border-radius: 28.5px
      background-color: #d9d9d9
@media (max-width: 1023px)
  .navbar-brand,
  .navbar-menu
    background-color: rgba(48, 48, 48, 0.9)
  .navbar-burger
    margin-left: 0
    background-color: transparent
    span
      width: 30px
      height: 4px
      left: calc(35% - 8px)
      color: #ffffff
      &:nth-child(1)
        top: calc(50% - 11px)
      &:nth-child(3)
        top: calc(50% + 9px)
  .navbar-burger.is-active
    span
      &:nth-child(1)
        top: calc(50% - 7px)
      &:nth-child(3)
        top: calc(50% + 3px)
  .navbar-logo
    margin-left: calc(50% - 110px)
  .navbar-item
    a
      &:hover
        background-color: transparent
    .is-active
      border-bottom: none
    .phone
      opacity: 0
</style>
